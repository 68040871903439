import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import BlogItem from "../components/BlogItem"
import { HeaderContainer } from "../components/HeaderContainer"
import { TwoRowContainer } from "../components/TwoRowContainer"

const SubmenuPage = ({ data }) => {
  const {
    translations,
    subMenus,
  } = data

  return (
    <>
      <Seo />
      <HeaderContainer>
        <h1>
          {translations.frontmatter.title}
        </h1>
      </HeaderContainer>

      { translations.html
        ? (
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: translations.html }}
          />
        )
        : <></>
      }
      
      <TwoRowContainer>
        {subMenus.edges.map(({ node }, index) => (
          <BlogItem nodeObj={node} index={index} />
        ))}
      </TwoRowContainer>
    </>
  )
}

export default SubmenuPage

export const submenuQuery = graphql`
  query submenuQuery($language: String!, $contentPath: String!, $path: String!) {
    translations: markdownRemark(fields: { path: { eq: $path } } ) {
      html
      frontmatter {
        title
      }
    }
    subMenus: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        fileAbsolutePath: { regex: $contentPath }
        fields: { language: { eq: $language } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            featuredImageAlt
          }
          excerpt
        }
      }
    }
  }
`
